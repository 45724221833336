import styled from "styled-components";

const StyledEspace = styled.section`
    background: linear-gradient(90deg, #e6f7ff, #b3e0ff);
    padding: 50px 30px;
    color: #2c5c5c;
`;

const EspaceWrapper = styled.div`
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const BoxVidéo = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* aspect ratio 16:9 */
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
`;

const CadreStylisé = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const TexteDescription = styled.p`
    font-size: 20px;
    line-height: 1.8;
    color: #2c5c5c;
    margin-top: 20px;
`;

const BoîteFormulaire = styled.div`
    background: rgba(255, 255, 255, 0.9);
    padding: 35px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const FormulaireInput = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const ChampInput = styled.input`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 18px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #4dff4d;
        outline: none;
    }
`;

const BoutonSoumettre = styled.button`
    padding: 16px;
    background-color: #4dff4d;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #1aff1a;
    }
`;

const BoîteAccord = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #2c5c5c;
    margin-top: 15px;
    text-align: left;

    input[type="checkbox"] {
        margin-right: 10px;
    }

    a {
        color: #4dff4d;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const InfinityComponent = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        fetch("thanks/index.php", {
            method: "POST",
            body: formData,
        }).catch((error) => {
            console.error("表单提交错误:", error);
        });

        alert("请求已发送");
        e.target.reset();
    };

    return (
        <StyledEspace>
            <EspaceWrapper>
                <div>
                    <BoxVidéo>
                        <CadreStylisé
                            src="https://www.youtube.com/embed/6c-Q7XyGMTw?si=K4UeKBZEBpkYSF_F"
                            title="无限视频"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </BoxVidéo>
                    <TexteDescription>
                        无限是一个挑战人类理解并激励想象力的概念。它代表着无限的可能性，一段穿越思想、梦想和发现的无尽旅程。在我们的项目中，无限象征着创造力、技术和激情的融合，邀请探索新视野，打破界限，追寻充满机会的未来。
                    </TexteDescription>
                </div>
                <BoîteFormulaire>
                    <h3 style={{ marginBottom: "25px", color: "#2c5c5c" }}>注册</h3>
                    <FormulaireInput onSubmit={handleSubmit}>
                        <ChampInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder="输入您的姓名"
                            required
                        />
                        <ChampInput
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="输入您的名字"
                            required
                        />
                        <ChampInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="示例@mail.com"
                            required
                        />
                        <ChampInput
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="+34 600 000 000"
                            required
                        />
                        <BoîteAccord>
                            <input type="checkbox" id="terms" name="terms" required />
                            <label htmlFor="terms">
                                我接受{" "}
                                <a href="/terms" rel="noopener noreferrer">
                                    条款和条件
                                </a>{" "}
                                和{" "}
                                <a href="/cookies" rel="noopener noreferrer">
                                    cookie政策
                                </a>
                                及{" "}
                                <a href="/policy" rel="noopener noreferrer">
                                    隐私政策
                                </a>
                            </label>
                        </BoîteAccord>
                        <BoutonSoumettre type="submit">提交请求</BoutonSoumettre>
                    </FormulaireInput>
                </BoîteFormulaire>
            </EspaceWrapper>
        </StyledEspace>
    );
};
export default InfinityComponent;