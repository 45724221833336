import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 40px 20px;
    background-color: #fff2e6;
    box-sizing: border-box;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
    color: #4d2600;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
    justify-content: center;
`;

const Card = styled.div`
    background-color: #ffe6cc;
    border-radius: 12px;
    overflow: hidden;
    max-width: 340px;
    flex: 1;
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
    }
`;

const Image = styled.img`
    width: 100%;
    height: 220px;
    object-fit: cover;
`;

const Content = styled.div`
    padding: 25px;
`;

const Title = styled.h3`
    font-size: 22px;
    margin-bottom: 12px;
    color: #4d2600;
    font-weight: 600;
`;

const Date = styled.p`
    font-size: 16px;
    color: #7f5c3c;
    margin-bottom: 12px;
`;

const Excerpt = styled.p`
    font-size: 18px;
    color: #5e3a26;
    line-height: 1.6;
    margin-bottom: 18px;
`;

const Button = styled.a`
    display: inline-block;
    padding: 12px 22px;
    background-color: #4d2600;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    font-size: 18px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #993d00;
    }
`;

const posts = [
  {
    image: "blog1.jpg",
    title: "新数字平台启动",
    date: "2025年4月15日",
    excerpt:
      "无限科技西班牙推出其新的数字平台，旨在彻底改变用户体验并简化业务流程。",
    link: "#"
  },
  {
    image: "blog2.jpg",
    title: "人工智能创新",
    date: "2025年4月10日",
    excerpt:
      "发现我们的最新人工智能创新如何改变公司在数字时代的运营方式。",
    link: "#"
  },
  {
    image: "blog3.jpg",
    title: "增长的战略合作伙伴关系",
    date: "2025年4月5日",
    excerpt:
      "我们建立了新的战略合作伙伴关系，将推动我们的增长并扩展科技行业的商业机会。",
    link: "#"
  }
];

const BlogComponent = () => {
  return (
    <Wrapper>
      <Heading>最新消息</Heading>
      <Grid>
        {posts.map((post, index) => (
          <Card key={index}>
            <Image src={post.image} alt={post.title} />
            <Content>
              <Title>{post.title}</Title>
              <Date>{post.date}</Date>
              <Excerpt>{post.excerpt}</Excerpt>
              <Button href={post.link}>阅读更多</Button>
            </Content>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default BlogComponent;