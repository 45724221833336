import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.section`
    width: 100%;
    padding: 50px 15px;
    background: linear-gradient(270deg, #ffcc00, #ff9900);
    box-sizing: border-box;
    text-align: center;
    color: #333;
`;

const StyledTitle = styled.h2`
    font-size: 36px;
    margin-bottom: 20px;
`;

const StyledText = styled.p`
    font-size: 20px;
    margin-bottom: 30px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledInput = styled.input`
    padding: 14px 20px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 300px;
`;

const StyledButton = styled.button`
    padding: 14px 32px;
    font-size: 20px;
    background-color: #333;
    color: #ffcc00;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.4s;

    &:hover {
        background-color: #ff6600;
    }
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`订阅成功：${email}`);
    setEmail('');
  };

  return (
    <StyledContainer>
      <StyledTitle>订阅我们的新闻</StyledTitle>
      <StyledText>
        了解最新动态和Infinity Tech Ukraine的独家优惠。
      </StyledText>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="email"
          placeholder="输入您的电子邮件"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <StyledButton type="submit">注册</StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default Newsletter;