import styled from "styled-components";

const 新的包装 = styled.section`
  width: 100%;
  padding: 50px 15px;
  background: linear-gradient(45deg, #f5f5dc, #ffe4b5);
  box-sizing: border-box;
`;

const 新的容器 = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const 新的信息 = styled.div`
  flex: 1;
  min-width: 250px;
  text-align: center;
`;

const 新的图片 = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
`;

const 新的文本 = styled.div`
    flex: 1;
    min-width: 250px;
`;

const 新的标题 = styled.h2`
    font-size: 32px;
    color: #ff8c00;
    margin-bottom: 15px;
    font-weight: bold;
`;

const 新的段落 = styled.p`
    font-size: 16px;
    color: #4e3b31;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const About = () => {
  return (
    <新的包装>
      <新的容器>
        <新的文本>
          <新的标题>关于不同科技解决方案</新的标题>
          <新的段落>
            在不同科技解决方案公司，我们致力于通过创造性的数字解决方案来塑造未来。我们位于新加坡，推动数字创新和人工智能的发展，以优化运营并促进进步。
          </新的段落>
          <新的段落>
            我们敬业的团队充满热情和创意，预见未来的挑战，并提供前沿技术，为企业开启新的可能性。来探索一个充满机会的世界吧！
          </新的段落>
        </新的文本>
      </新的容器>
    </新的包装>
  );
};
export default About;