import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #e1f5fe, #b3e5fc);
    box-sizing: border-box;
    color: #4f83e8;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 50px;
    color: #1976d2;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
`;

const Card = styled.div`
    background: rgba(255, 255, 255, 0.95);
    padding: 30px 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const Photo = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 15px auto;
    display: block;
    border: 3px solid #b3e5fc;
`;

const Name = styled.h3`
    font-size: 20px;
    margin-bottom: 5px;
    color: #1976d2;
    text-align: center;
`;

const Position = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    color: #4f83e8;
    text-align: center;
    opacity: 0.8;
`;

const Text = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #4f83e8;
    text-align: center;
`;

const data = [
  {
    photo: "test1.jpg",
    name: "林小强",
    position: "总裁, 公司X",
    review:
      "Infinity Tech 真正改变了我们的工作流程。他们热情和专业的方式极大地提升了我们的成功。"
  },
  {
    photo: "test3.jpg",
    name: "杨明",
    position: "首席技术官, 公司Y",
    review:
      "Infinity团队的创造力和投入使一切变得不同。他们的创新解决方案帮助我们克服了市场挑战。"
  },
  {
    photo: "test2.jpg",
    name: "李华",
    position: "市场总监, 公司Z",
    review:
      "与Infinity Tech合作是一次丰富的体验。他们的个性化关注和专业知识提升了我们的市场策略。"
  }
];

const Testimonials = () => {
  return (
    <Wrapper>
      <Heading>客户反馈</Heading>
      <Grid>
        {data.map((item, index) => (
          <Card key={index}>
            <Photo src={item.photo} alt={item.name} />
            <Name>{item.name}</Name>
            <Position>{item.position}</Position>
            <Text>{item.review}</Text>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default Testimonials;