import styled from "styled-components";

const NewContainer = styled.section`
    background: linear-gradient(135deg, #ff6f61, #ffccbc);
    padding: 40px 20px;
    color: #000;
`;

const NewCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;

const NewCard = styled.div`
    flex: 1;
    min-width: 250px;
    max-width: 280px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
`;

const NewCardImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const NewCardContent = styled.div`
    padding: 20px;
    text-align: center;
`;

const NewCardTitle = styled.h3`
    font-size: 26px;
    margin-bottom: 12px;
    color: #e64a19;
`;

const NewCardDescription = styled.p`
    font-size: 18px;
    line-height: 1.5;
    color: #757575;
`;

const PrivilegesInfinity = () => {
  return (
    <NewContainer>
      <NewCardsContainer>
        <NewCard>
          <NewCardImage src="/reacti3.jpg" alt="持续创新" />
          <NewCardContent>
            <NewCardTitle>创意想法</NewCardTitle>
            <NewCardDescription>
              拥抱不断涌现的创新理念，彻底改革您的商业实践。
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti2.jpg" alt="综合解决方案" />
          <NewCardContent>
            <NewCardTitle>完整解决方案</NewCardTitle>
            <NewCardDescription>
              探索全面的技术服务，以优化您组织的各个方面。
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti1.jpg" alt="技术专家" />
          <NewCardContent>
            <NewCardTitle>技术专家</NewCardTitle>
            <NewCardDescription>
              我们的专家团队为您量身定制策略，有效推动您的数字化进程。
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
      </NewCardsContainer>
    </NewContainer>
  );
};
export default PrivilegesInfinity;