import React from "react";
import styled from "styled-components";

const StyledProjectContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
    padding: 60px 20px;
    justify-items: center;
    background: linear-gradient(90deg, #e9f3f4, #b3e5fc);
    box-sizing: border-box;
`;

const StyledImageContainer = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const StyledContentContainer = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #e1f5fe;
    border-radius: 20px;
    padding: 30px 30px 40px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const StyledProjectTitle = styled.h2`
    font-size: 26px;
    color: #0277bd;
    margin-bottom: 20px;
    font-weight: bold;
    position: relative;
    z-index: 2;
`;

const StyledProjectDescription = styled.p`
    font-size: 18px;
    color: #0d47a1;
    line-height: 1.6;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
`;

const Decoration = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: 8px;
    height: 100%;
    background-color: #0277bd;
    border-radius: 0 5px 5px 0;
    z-index: 1;
`;

const ProjectInfinityBlock = () => {
  return (
    <StyledProjectContainer>
      <StyledImageContainer>
        <StyledImage src="/infinity.jpg" alt="无限项目" />
      </StyledImageContainer>
      <StyledContentContainer>
        <Decoration />
        <StyledProjectTitle>无限项目：无限创新</StyledProjectTitle>
        <StyledProjectDescription>
          探索无限，在这里创造力与科技交汇，推动革命性解决方案。
          我们的项目重新定义未来，提供颠覆性工具和创新策略，改变各个行业。
          加入无限革命，成为变革的一部分！
        </StyledProjectDescription>
      </StyledContentContainer>
    </StyledProjectContainer>
  );
};

export default ProjectInfinityBlock;