import React from "react";
import styled from "styled-components";

const PrivacyWrapper = styled.section`
    background: #fff8e1;
    color: #4a4a4a;
    padding: 60px 30px;
`;

const PrivacyContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
    font-size: 36px;
    font-weight: bold;
    color: #8d6e63;
    margin-bottom: 30px;
    text-align: left;
`;

const PrivacyText = styled.p`
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 25px;
`;

const PrivacyList = styled.ul`
    margin: 25px 0;
    padding-left: 30px;
`;

const PrivacyListItem = styled.li`
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 15px;
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyWrapper>
            <PrivacyContainer>
                <PrivacyTitle>隐私政策</PrivacyTitle>
                <PrivacyText>
                    在**即刻灵活**，我们非常重视您的个人数据保护。本隐私政策解释了我们在您使用我们的网站时如何收集、使用和保护您的信息。
                </PrivacyText>

                <PrivacyTitle>1. 信息收集</PrivacyTitle>
                <PrivacyText>
                    当您使用我们的服务时，我们会收集不同的个人信息，包括：
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>您的姓名</PrivacyListItem>
                    <PrivacyListItem>您的电子邮件地址</PrivacyListItem>
                    <PrivacyListItem>您的电话号码</PrivacyListItem>
                    <PrivacyListItem>技术信息，如您的IP地址和设备类型</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>2. 数据使用</PrivacyTitle>
                <PrivacyText>
                    您的数据用于：
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>提供和改善我们的服务</PrivacyListItem>
                    <PrivacyListItem>在必要时与您联系</PrivacyListItem>
                    <PrivacyListItem>确保网站的安全和正常运行</PrivacyListItem>
                    <PrivacyListItem>在您同意的情况下向您发送优惠和更新</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>3. 数据分享</PrivacyTitle>
                <PrivacyText>
                    我们不会出售或租赁您的个人数据。然而，我们可能会与可信赖的合作伙伴分享这些数据，仅用于：
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>遵守法律义务</PrivacyListItem>
                    <PrivacyListItem>借助分析工具改善我们的服务</PrivacyListItem>
                    <PrivacyListItem>确保我们平台的安全</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>4. 数据安全</PrivacyTitle>
                <PrivacyText>
                    我们采取严格的安全措施来保护您的信息免受未授权访问、修改或披露。
                </PrivacyText>

                <PrivacyTitle>5. 您的权利</PrivacyTitle>
                <PrivacyText>
                    您有权：
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>访问您的个人数据</PrivacyListItem>
                    <PrivacyListItem>请求更正或删除您的信息</PrivacyListItem>
                    <PrivacyListItem>随时撤回您的同意</PrivacyListItem>
                    <PrivacyListItem>向数据保护机构提出投诉</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>6. Cookies</PrivacyTitle>
                <PrivacyText>
                    我们使用Cookies来改善您在我们网站上的体验。您可以随时在浏览器设置中修改您的Cookie偏好。
                </PrivacyText>

                <PrivacyTitle>7. 政策修改</PrivacyTitle>
                <PrivacyText>
                    我们保留随时修改本隐私政策的权利。任何修改将在此页面上发布。
                </PrivacyText>

                <PrivacyTitle>8. 联系方式</PrivacyTitle>
                <PrivacyText>
                    如有关于我们隐私政策的任何问题，您可以通过以下方式与我们联系：
                </PrivacyText>
                <PrivacyText>
                    📧 邮箱：contact@immidiateflex.fr
                </PrivacyText>
                <PrivacyText>
                    📍 地址：15 Rue de la Lumière, 75000 Paris, France
                </PrivacyText>
            </PrivacyContainer>
        </PrivacyWrapper>
    );
};

export default PrivacyPolicy;