import React from "react";
import styled from "styled-components";

const UniqueContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: #ffebcd;
    box-sizing: border-box;
`;

const UniqueTitle = styled.h2`
    text-align: center;
    font-size: 30px;
    margin-bottom: 50px;
    color: #ff4500;
    font-weight: bold;
`;

const UniqueGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`;

const UniqueCard = styled.div`
    background-color: #ffe4b5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    flex: 1;
    min-width: 240px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
`;

const UniqueImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const UniqueContent = styled.div`
    padding: 15px;
`;

const UniqueCardTitle = styled.h3`
    font-size: 20px;
    margin-bottom: 10px;
    color: #ff4500;
`;

const UniqueCardDescription = styled.p`
    font-size: 14px;
    color: #555;
    line-height: 1.3;
`;

const portfolioData = [
  {
    image: "port1.jpg",
    title: "数字化转型",
    description:
      "我们开发创新的数字解决方案，以优化流程并提升各个行业的生产力。"
  },
  {
    image: "port2.jpg",
    title: "用户体验",
    description:
      "我们创建以用户体验为中心的数字平台，结合技术和战略以创造附加值。"
  },
  {
    image: "port3.jpg",
    title: "高级定制",
    description:
      "我们实施定制化解决方案，以满足每个客户独特的需求，推动他们在市场上的竞争力。"
  },
  {
    image: "port4.jpg",
    title: "创新策略",
    description:
      "我们设计和执行颠覆性的数字策略，以推动企业增长和业务转型。"
  }
];

const UniqueComponent = () => {
  return (
    <UniqueContainer>
      <UniqueTitle>我们的独特提案</UniqueTitle>
      <UniqueGrid>
        {portfolioData.map((item, index) => (
          <UniqueCard key={index}>
            <UniqueImage src={item.image} alt={item.title} />
            <UniqueContent>
              <UniqueCardTitle>{item.title}</UniqueCardTitle>
              <UniqueCardDescription>{item.description}</UniqueCardDescription>
            </UniqueContent>
          </UniqueCard>
        ))}
      </UniqueGrid>
    </UniqueContainer>
  );
};

export default UniqueComponent;