import styled from "styled-components";

const StyledContainer = styled.section`
    width: 100%;
    padding: 40px 10px;
    background: linear-gradient(135deg, #aaffcc, #ccffee);
    box-sizing: border-box;
`;

const StyledBlock = styled.div`
    max-width: 700px;
    margin: 30px auto;
    padding: 30px 20px;
    background-color: #d1d1d1;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

const StyledHeading = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #008000;
    font-weight: bold;
`;

const StyledText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: #00008b;

    p {
        margin-bottom: 15px;
    }

    a {
        color: #008000;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <StyledContainer>
      <StyledBlock>
        <StyledHeading>数据隐私声明</StyledHeading>
        <StyledText>
          <p>
            在数据保护公司，我们重视您的隐私，并致力于保护您的个人信息。我们的数据隐私声明解释了我们如何在您访问我们的平台时收集、使用和保护您的数据。
          </p>
          <p>
            我们可能会使用 cookies 和类似技术来改善您的浏览体验，评估网站流量，并根据您的偏好个性化内容。继续使用我们的平台，即表示您接受我们的数据隐私声明。
          </p>
          <p>
            您可以通过浏览器设置管理或删除 cookies，尽管这可能会影响我们平台的功能和个性化。
          </p>
          <p>
            欲了解更多详细信息，请查看我们的{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              数据隐私声明
            </a>。
          </p>
        </StyledText>
      </StyledBlock>
    </StyledContainer>
  );
};
export default CookiePolicy;