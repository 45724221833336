import CountUp from "react-countup";
import styled from "styled-components";
import { FaRocket, FaLightbulb, FaHeart } from "react-icons/fa";

const 新节段 = styled.section`
    background: linear-gradient(90deg, #e1f5fe, #b3e5fc);
    padding: 50px 30px;
    color: #0d47a1;
`;

const 新标题 = styled.h2`
    text-align: center;
    font-size: 36px;
    margin-bottom: 50px;
    color: #0288d1;
    font-weight: bold;
`;

const 新网格容器 = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
`;

const 新盒子 = styled.div`
    background: #e3f2fd;
    padding: 40px 30px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
`;

const 新图标容器 = styled.div`
    font-size: 42px;
    margin-bottom: 15px;
    color: #0288d1;
`;

const 新数字文本 = styled.div`
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0288d1;
`;

const 新描述 = styled.div`
    font-size: 20px;
    color: #0d47a1;
`;

const 为什么选择我们 = () => {
  return (
    <新节段>
      <新标题>我们与众不同之处</新标题>
      <新网格容器>
        <新盒子>
          <新图标容器>
            <FaRocket />
          </新图标容器>
          <新数字文本>
            <CountUp end={56789} duration={3} separator="," />
          </新数字文本>
          <新描述>成功企业</新描述>
        </新盒子>
        <新盒子>
          <新图标容器>
            <FaLightbulb />
          </新图标容器>
          <新数字文本>
            <CountUp end={92} duration={3} separator="," />
          </新数字文本>
          <新描述>创新解决方案</新描述>
        </新盒子>
        <新盒子>
          <新图标容器>
            <FaHeart />
          </新图标容器>
          <新数字文本>
            <CountUp end={9876} duration={3} separator="," />
          </新数字文本>
          <新描述>满意客户</新描述>
        </新盒子>
      </新网格容器>
    </新节段>
  );
};
export default 为什么选择我们;