import styled from "styled-components";

const NewWrapper = styled.div`
    max-width: 900px;
    margin: 60px auto 30px;
    padding: 30px 20px;
    background-color: #fff8e1;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(255, 165, 0, 0.4);
`;

const NewHeaderText = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #ff6f00;
    font-weight: bold;
`;

const NewTextContent = styled.div`
    font-size: 20px;
    line-height: 1.8;
    color: #4e342e;

    p {
        margin-bottom: 15px;
    }

    strong {
        color: #ff6f00;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <NewWrapper>
        <NewHeaderText>新条款和条件</NewHeaderText>
        <NewTextContent>
          <p>
            欢迎来到无限科技西班牙。访问我们的网站即表示您接受这些条款和条件，这些条款和条件可以随时在不通知的情况下更新。我们建议您定期查看以保持信息的更新。
          </p>
          <p>
            <strong>1. 网站使用：</strong> 用户承诺以道德和法律规定的方式使用网站。禁止使用网站进行可能危害信息安全或完整性的活动。
          </p>
          <p>
            <strong>2. 财产权：</strong> 所有内容，包括文本、图像、徽标和软件，均为无限科技西班牙的独家财产，并受知识产权法的保护。
          </p>
          <p>
            <strong>3. 责任限制：</strong> 无限科技西班牙不对因使用或无法使用网站而导致的直接、间接或后果性损害负责。
          </p>
          <p>
            <strong>4. 更新和修改：</strong> 我们保留在任何时间无需通知即可修改、更新或删除这些条款的任何部分的权利。继续使用网站即表示您接受这些修改。
          </p>
          <p>
            <strong>5. 法律和管辖权：</strong> 这些条款将根据西班牙法律进行管辖和解释。如有争议，双方将提交马德里法院的专属管辖权。
          </p>
          <p>
            如果您有任何问题或需要更多信息，请随时与我们联系。
          </p>
        </NewTextContent>
      </NewWrapper>
    </>
  );
};
export default TermsAndConditions;