import styled from "styled-components";
import LocationBlock from "../Components/BloqueUbicacion";

const StyledSection = styled.section`
    width: 100%;
    padding: 100px 30px;
    background: linear-gradient(135deg, #ff7f50, #ff6347);
    box-sizing: border-box;
`;

const StyledContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: flex-start;
`;

const StyledInfoSection = styled.div`
    flex: 1;
    min-width: 350px;
    padding: 30px;
    background: #fff3e0;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
`;

const StyledFormSection = styled.div`
    flex: 1;
    min-width: 350px;
    padding: 30px;
    background: #fff3e0;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
`;

const StyledHeading = styled.h1`
    font-size: 40px;
    color: #8b4513;
    text-align: center;
    margin-bottom: 50px;
    font-weight: normal;
`;

const StyledSectionHeading = styled.h2`
    font-size: 32px;
    color: #8b4513;
    margin-bottom: 25px;
    text-align: center;
`;

const StyledInfoText = styled.p`
    font-size: 20px;
    color: #5f4b8a;
    margin-bottom: 20px;
    text-align: center;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const StyledInput = styled.input`
  padding: 16px;
  border: 1px solid #e0a16a;
  border-radius: 8px;
  font-size: 18px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #8b4513;
    outline: none;
  }
`;

const StyledTextarea = styled.textarea`
  padding: 16px;
  border: 1px solid #e0a16a;
  border-radius: 8px;
  font-size: 18px;
  resize: vertical;
  min-height: 180px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #8b4513;
    outline: none;
  }
`;

const StyledButton = styled.button`
  padding: 16px;
  background-color: #8b4513;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d2691e;
  }
`;

const Contact = () => {
  return (
    <StyledSection>
      <StyledHeading>联系我们</StyledHeading>
      <StyledContainer>
        <StyledInfoSection>
          <StyledSectionHeading>联系方式</StyledSectionHeading>
          <StyledInfoText>
            <strong>电话：</strong> +65 1234 5678
          </StyledInfoText>
          <StyledInfoText>
            <strong>电子邮件：</strong> contact@primeavrora.sg
          </StyledInfoText>
          <StyledInfoText>
            <strong>地址：</strong> 虚构街道，123号，新加坡
          </StyledInfoText>
        </StyledInfoSection>
        <StyledFormSection>
          <StyledSectionHeading>给我们留言</StyledSectionHeading>
          <StyledForm action="thanks/index.php" method="post">
            <StyledInput type="text" name="name" placeholder="你的名字" required />
            <StyledInput type="email" name="email" placeholder="你的电子邮件" required />
            <StyledInput type="text" name="subject" placeholder="主题" required />
            <StyledTextarea name="message" placeholder="在这里写下你的消息..." required />
            <StyledButton type="submit">发送消息</StyledButton>
          </StyledForm>
        </StyledFormSection>
      </StyledContainer>
      <div style={{ marginTop: "50px" }}>

      </div>
    </StyledSection>
  );
};
export default Contact;