import React from "react";
import styled from "styled-components";

const CustomFooter = styled.footer`
  background: linear-gradient(90deg, #FF7043, #FFAB91);
  color: #212121;
  padding: 60px 25px 40px;
  box-sizing: border-box;
  border-top: 2px solid rgba(0, 0, 0, 0.7);
`;

const CustomFooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
`;

const CustomSection = styled.div`
  flex: 1;
  min-width: 220px;
`;

const CustomSectionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #FF5722;
`;

const CustomText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.6;
`;

const CustomFooterLink = styled.a`
  color: #212121;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
  transition: color 0.3s;

  &:hover {
    color: #FF5722;
  }
`;

const CustomFooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  padding-top: 20px;
  font-size: 14px;
  margin-top: 25px;
`;

const Footer = () => {
  return (
      <CustomFooter>
        <CustomFooterContent>
          <CustomSection>
            <CustomSectionTitle>光辉的未来</CustomSectionTitle>
            <CustomText>地址：15号，光明街，75000 巴黎，法国</CustomText>
            <CustomText>电话：+33 1 23 45 67 89</CustomText>
            <CustomText>电子邮件：contact@光辉未来.fr</CustomText>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>链接</CustomSectionTitle>
            <CustomFooterLink href="/" target="_blank" rel="noopener noreferrer">
              首页
            </CustomFooterLink>
            <CustomFooterLink href="/about" target="_blank" rel="noopener noreferrer">
              关于我们
            </CustomFooterLink>
            <CustomFooterLink href="/contact" target="_blank" rel="noopener noreferrer">
              联系我们
            </CustomFooterLink>
            <CustomFooterLink href="/policy" target="_blank" rel="noopener noreferrer">
              隐私政策
            </CustomFooterLink>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>关注我们</CustomSectionTitle>
            <CustomFooterLink
                href="https://www.facebook.com/光辉的未来"
                target="_blank"
                rel="noopener noreferrer"
            >
              脸书
            </CustomFooterLink>
            <CustomFooterLink
                href="https://twitter.com/光辉的未来"
                target="_blank"
                rel="noopener noreferrer"
            >
              推特
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.linkedin.com/company/光辉的未来"
                target="_blank"
                rel="noopener noreferrer"
            >
              领英
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.instagram.com/光辉的未来"
                target="_blank"
                rel="noopener noreferrer"
            >
              Instagram
            </CustomFooterLink>
          </CustomSection>
        </CustomFooterContent>
        <CustomFooterBottom>
          &copy; {new Date().getFullYear()} 光辉的未来. 保留所有权利.
        </CustomFooterBottom>
      </CustomFooter>
  );
};

export default Footer;